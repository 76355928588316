<template lang="pug">
  div
    template(v-if="!loading")
      v-card.mb-2(
        v-for="(item, index) in supplies"
        :key="index")
        .list-1
          .list-1__title {{ item.ingredient_name }}
          .list-1__item
            .list-1__item-label {{ 'pages.storage.amount_supply' | translate }} [{{ 'units.kg' | translate }}]
            .list-1__item-value {{ item.weight }}
          .list-1__item
            .list-1__item-label {{ 'base.price' | translate }} [{{ 'units.uah' | translate }}]
            .list-1__item-value {{ item.price }}
          .list-1__item
            .list-1__item-label {{ 'base.provider' | translate }}
            .list-1__item-value {{ item.provider_name }}
          .list-1__item
            .list-1__item-label {{ 'base.date' | translate }}
            .list-1__item-value {{ item.created_at | date }}

      v-table-body(
        v-if="!loading"
        outsideTable
        :empty="!supplies.length")

    template(v-else)
      v-card.mb-2(v-for="i in 5" :key="i")
        .list-1
          .list-1__title
            .list-1__title-skeleton
          .list-1__item(v-for="j in 4" :key="`${i}-${j}`")
            .list-1__item-skeleton

    template(
      v-if="!loading && showPagination")
      v-paginate(
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageChangeHandler")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import paginationMixin from '@/mixins/pagination.mixin'

export default {
  name: 'SuppliesTableMobile',

  mixins: [paginationMixin],

  mounted () {
    this.setupPagination(this.fetchSupplies)
  },

  methods: {
    ...mapActions(['fetchSupplies'])
  },

  computed: {
    ...mapGetters([
      'supplies'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
